// export const API_BASE_URL = "https://api.testserver.rhinolister.com/api";
// export const IMAGE_PATH="https://api.testserver.rhinolister.com/images/";
export const ImportManifestURL = "https://api.myglobalsecure.io/UPLOADMAN/rhino";

export const API_BASE_URL = "https://api.rhinolister.com/api";
export const IMAGE_PATH="https://api.rhinolister.com/images/";

// export const IMAGE_PATH="http://localhost:8000/images/";
// export const API_BASE_URL = "http://localhost:8000/api/";

// export const GET_IP_ADDRESS="https://api.ipify.org?format=json"; 